<template>
  <section>
    <v-card class="v-card--auth pa-5 outlined" max-width="500" outlined>
      <v-container pa-0 grid-list-xl>
        <v-form ref="form" v-model="form" @keyup.native.enter="doForgotPassword" @submit.prevent class="mb-5"
          style="padding: 1rem 1rem 0rem 1rem">
          <h1 class="headline font-weight-regular primary--text">
            Glemt kodeord
          </h1>
          <v-container pa-0>
            <v-row>
              <v-text-field autofocus v-model="email" @input="setAuthEmail" label="Email" name="email"
                persistent-hint />
            </v-row>
            <v-row>
              <v-btn color="accent" @click="doForgotPassword" v-bind:loading="isLoading">Send</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  data() {
    const data = {
      email: this.$store.state.authEmail,
      password: undefined,

      form: false,
      isLoading: false,
    };
    return data;
  },
  mounted: function () { },
  methods: {
    ...mapActions("cognito", ["forgotPassword"]),
    ...mapMutations(["setSnackbar", "setAuthEmail"]),
    doForgotPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      this.forgotPassword({
        username: this.email,
      })
        .then(() => {
          this.setSnackbar({
            type: "success",
            msg: "Email med bekræftelseskode afsendt",
          });
          this.$router.push({ name: "resetpassword" });
        })
        .catch((err) => {
          this.setSnackbar({
            type: "error",
            msg: err.message,
            timeout: 10000,
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
